import axios from 'axios'

const url = '/api/v1/shorten'

class UrlService {
  static shortenUrl(body, apiKey) {
    return axios.post(url, body, { headers: { 'x-api-key': apiKey } })
  }
}

export default UrlService
