import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _009ccf4f = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _2f759e71 = () => interopDefault(import('../pages/api-change.vue' /* webpackChunkName: "pages/api-change" */))
const _fbd63f68 = () => interopDefault(import('../pages/banned.vue' /* webpackChunkName: "pages/banned" */))
const _4f230be2 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _2054aa14 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _bdcfcb2e = () => interopDefault(import('../pages/docs.vue' /* webpackChunkName: "pages/docs" */))
const _51c3fe11 = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _6b5b2f6e = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _009ccf4f,
    name: "about___en"
  }, {
    path: "/api-change",
    component: _2f759e71,
    name: "api-change___en"
  }, {
    path: "/banned",
    component: _fbd63f68,
    name: "banned"
  }, {
    path: "/contact",
    component: _4f230be2,
    name: "contact___en"
  }, {
    path: "/de",
    component: _2054aa14,
    name: "index___de"
  }, {
    path: "/docs",
    component: _bdcfcb2e,
    name: "docs___en"
  }, {
    path: "/fr",
    component: _2054aa14,
    name: "index___fr"
  }, {
    path: "/super_secret_admin_page",
    component: _51c3fe11,
    name: "admin___en"
  }, {
    path: "/terms",
    component: _6b5b2f6e,
    name: "terms___en"
  }, {
    path: "/de/about",
    component: _009ccf4f,
    name: "about___de"
  }, {
    path: "/de/api-change",
    component: _2f759e71,
    name: "api-change___de"
  }, {
    path: "/de/contact",
    component: _4f230be2,
    name: "contact___de"
  }, {
    path: "/de/super_secret_admin_page",
    component: _51c3fe11,
    name: "admin___de"
  }, {
    path: "/de/terms",
    component: _6b5b2f6e,
    name: "terms___de"
  }, {
    path: "/fr/about",
    component: _009ccf4f,
    name: "about___fr"
  }, {
    path: "/fr/api-change",
    component: _2f759e71,
    name: "api-change___fr"
  }, {
    path: "/fr/contact",
    component: _4f230be2,
    name: "contact___fr"
  }, {
    path: "/fr/super_secret_admin_page",
    component: _51c3fe11,
    name: "admin___fr"
  }, {
    path: "/fr/terms",
    component: _6b5b2f6e,
    name: "terms___fr"
  }, {
    path: "/",
    component: _2054aa14,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
