const en = [
  {
    label: 'Never',
    value: 0,
  },
  {
    label: '5 Minutes',
    value: 5,
  },
  {
    label: '1 Hour',
    value: 60,
  },
  {
    label: '1 Day',
    value: 1440,
  },
  {
    label: '1 Week',
    value: 10080,
  },
  {
    label: '1 Month',
    value: 43800,
  },
  {
    label: '6 Months',
    value: 262800,
  },
  {
    label: '1 Year',
    value: 525600,
  },
]

const de = [
  {
    label: 'Nie',
    value: 0,
  },
  {
    label: '5 Minuten',
    value: 5,
  },
  {
    label: '1 Stunde',
    value: 60,
  },
  {
    label: '1 Tag',
    value: 1440,
  },
  {
    label: '1 Woche',
    value: 10080,
  },
  {
    label: '1 Monat',
    value: 43800,
  },
  {
    label: '6 Monate',
    value: 262800,
  },
  {
    label: '1 Jahr',
    value: 525600,
  },
]

const fr = [
  {
    label: 'Jamais',
    value: 0,
  },
  {
    label: '5 Minutes',
    value: 5,
  },
  {
    label: '1 Heure',
    value: 60,
  },
  {
    label: '1 Jour',
    value: 1440,
  },
  {
    label: '1 Semaine',
    value: 10080,
  },
  {
    label: '1 Mois',
    value: 43800,
  },
  {
    label: '6 Mois',
    value: 262800,
  },
  {
    label: '1 An',
    value: 525600,
  },
]

module.exports = { en, de, fr }
