export default {
  about: {
    about: 'is a simple, modern, free and  privacy-friendly URL shortener.',
    bookmarklet: {
      explanation:
        "Once this is on your toolbar, you'll be able to shorten a URL at the click of a button. By clicking on the 'shorten url' bookmark, a chl.li link will be created for the page you are currently at.",
      guide:
        'Do you want to quickly shorten the link for the site you are currently on? Click and drag the following link to your bookmarks toolbar. ',
      shorten: 'shorten url',
    },
    createdBy: 'Made by',
    data: {
      alias: 'the short code',
      created: 'the date created',
      description:
        'does not track nor store any personal information. The only data we store when you create a short link are the following',
      done: "That's it.",
      expires: 'the expiry date (if one has been set)',
      url: 'the long URL',
    },
    privacy: {
      '1': 'Since we deeply care about privacy, chl.li',
      '2': 'does not',
      '3': ' track who, when or where someone clicked on a shortened link.',
    },
    thanks: 'Thank you for using chl.li!',
    title: 'About chl.li',
  },
  contact: {
    abuse: {
      form: {
        placeholder: 'Provide a suspicious link',
        report: 'Report Abuse',
        reportResponse: "We'll take a look at your report shortly.",
        thankYou: 'Thank you!',
        url: 'Short URL',
        urlError: {
          '1': 'Please enter a',
          '2': ' short link.',
        },
      },
      preferEmail:
        "If you'd prefer to email us instead (e.g. if you need a response) or have an issue the form isn't suitable to report, then please contact us at",
      reportResponse:
        'We aim to deal with abuse reports within 24 hours, but often manage much better. We will typically respond to reports by disabling affected shortened links and adding their destinations to our blacklist if appropriate.',
      title: 'Report Abuse',
    },
    inquiries: {
      title: 'General Inquiries',
      email: 'You may contact us at',
    },
    title: 'Contact Us',
  },
  footer: {
    about: 'About',
    api: 'Developer API',
    contact: 'Contact',
    home: 'Home',
  },
  header: {
    info: 'Swiss German: small link',
    lead: 'A simple, modern, free and privacy-friendly URL shortener.',
    apinotice: 'As of August 1, 2021, new chl.li links must be created with a paid API key. Existing links will continue to work as usual.',
    apinoticemore: 'Learn more',
    apikeyerror: {
      1: 'An API key is required for shortening a link. You can obtain one by visiting the',
      2: 'API change page',
      3: 'or enter your key below'
    }
  },
  meta: {
    '404': 'chl.li | page not found | 404',
    about: 'About chl.li',
    banned: 'chl.li | link disabled',
    contact: 'chl.li | contact',
    index: 'chl.li | URL shortener',
  },
  notFound: {
    caseSensitive: ' links are case sensitive.',
    error: {
      '1': "This is a 404 error, which means the link you've clicked on",
      '2': 'is either bad, or expired or you entered an invalid URL.',
    },
    title: 'Something‘s wrong here…',
  },
  shorten: {
    'alias-used':
      'This custom ending is already in use. Please chose a different one instead.',
    'alpha-only':
      'Only alphanumeric characters are allowed for the custom ending.',
    copied: 'Copied!',
    copy: 'Copy',
    'copy-failure': 'Failed to copy link.',
    expiry: 'expiry date',
    'invalid-url': 'Unable to shorten that link. It is not a valid URL.',
    loading: 'loading',
    never: 'Never',
    'new-link': 'Create another chl.li short link',
    'no-url': 'Please enter a URL to shorten.',
    placeholder: {
      alias: 'optional custom ending',
      url: 'paste a long URL',
      apikey: 'your API key',
    },
    'apikey-save': 'once entered, chl.li locally stores your API key so that you don\'t have to enter it every time you shorten a new link',
    'apikey-what': 'What\'s this?',
    'apikey-edit': 'Edit API Key',
    qrcode: 'Show QR code',
    'qrcode-hide': 'Hide QR code',
    'shorten-text': 'shorten url',
    shortened: 'shortened',
    shortening: 'shortening…',
    success: 'success',
    url: 'URL',
  },
}
