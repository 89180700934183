<template>
  <div class="app">
    <header>
      <h1 class="error--title">{{ $t('notFound.title') }}</h1>
    </header>
    <main v-if="error.statusCode === 404">
      <p>
        {{ $t('notFound.error.1') }} (<a
          :href="'https://chl.li' + $nuxt.$route.path"
          class="no-click"
          >chl.li{{ $nuxt.$route.path }}</a
        >)
        {{ $t('notFound.error.2') }}
      </p>
      <p>P.S. <a href="/">chl.li</a>{{ $t('notFound.caseSensitive') }}</p>
    </main>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer'

export default {
  components: {
    Footer,
  },
  props: { error: { type: Object, default: null } },
  head() {
    return { title: this.$t('meta.404') }
  },
}
</script>
