import Vue from 'vue'
import Prism from 'vue-prism-component'

import 'prismjs'

// import 'prismjs/themes/prism-funky.css'
import '@/assets/styles/prism-sop.css'

import 'prismjs/components/prism-scss.min'

import 'prismjs/plugins/autolinker/prism-autolinker.min'
import 'prismjs/plugins/autolinker/prism-autolinker.css'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-json'

Vue.component('prism', Prism)
