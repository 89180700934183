import Plausible from 'plausible-tracker';
const PlausiblePlugin = (context, inject) => {
    var _a;
    const optionsDomain = 'chl.li';
    const optionsHashMode = '';
    const optionsTrackLocalhost = 'false';
    const optionsApiHost = 'https://plausible.io';
    const options = Object.assign({ domain: optionsDomain.length ? optionsDomain : null, hashMode: optionsHashMode === 'true', trackLocalhost: optionsTrackLocalhost === 'true', apiHost: optionsApiHost.length ? optionsApiHost : 'https://plausible.io' }, (_a = context.$config) === null || _a === void 0 ? void 0 : _a.plausible);
    if (options.domain !== null) {
        const plausible = Plausible(options);
        plausible.enableAutoPageviews();
        inject('plausible', plausible);
    }
};
export default PlausiblePlugin;
